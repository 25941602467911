import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Tooltip
} from "@material-ui/core";
import * as PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import { styleWCAG } from "../../utils/WCAGThemeUtils";
import clsx from 'clsx';
import { getRandomNumberByTime } from "../../utils/NumberUtils";

const StyledCheckbox = withStyles({
    root: {
        padding: '0',
        marginRight: '15px'
    }
})(Checkbox);

const LimitWidthListItemText = withStyles({
    primary: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis'
    }
})(ListItemText);

const OutlinedSelectField = React.memo(({nullText, showErrors, id, required, multiple, label, value, onChange, options, autoFocus, helperText, error, name, ...otherProps}) => {
    const {t} = useTranslation('common');
    const inputLabelRef = useRef();
    const labelWidthState = useState(0);
    const [itemTextWith, setItemTextWidth] = useState('100%');
    const limitWidth = !!otherProps.limitWidth;
    const WCAGClasses = styleWCAG();

    useEffect(() => {
        labelWidthState[1](inputLabelRef.current.offsetWidth);

        if (limitWidth) {
            setItemTextWidth(inputLabelRef.current.offsetWidth);
        }
    }, []);

    const flattenedOptions = {};
    options.forEach(v => flattenedOptions[v.value] = v);

    if (typeof error === 'undefined') {
        error = showErrors && required && !value;
    }

    return <FormControl fullWidth required={required} variant={'outlined'} error={error} {...otherProps}>
        <InputLabel ref={inputLabelRef} htmlFor={id} id={id ? id + '-label' : undefined}>{label}</InputLabel>
        <Select
            multiple={multiple}
            value={multiple ? (value || []) : (value || (value === 0 ? 0 : ''))}
            onChange={event => {
                onChange(event.target.value, flattenedOptions[event.target.value])
            }}
            input={<OutlinedInput labelWidth={labelWidthState[0]}/>}
            labelId={id ? id + '-label' : undefined}
            inputProps={{ id }}
            name={name}
            renderValue={v => {
                if (multiple) {
                    return v.map(v => {
                        const value = flattenedOptions[v];
                        return value?.label || value?.primary;
                    }).filter(v => v !== '' && typeof(v) !== 'undefined' ).join(', ');
                } else {
                    const value = flattenedOptions[v];
                    return value?.label || value?.primary;
                }
            }}
            autoFocus={autoFocus}
            disabled={otherProps.disabled}
        >
            {!multiple && !required
                ? <MenuItem value={null}>
                    {nullText && <i>{t(nullText)}</i>}
                </MenuItem>
                : null}

            {options.map(o => {
                const optionId = `option-${getRandomNumberByTime()}`;

                return (
                    (typeof(o.show) === 'undefined' || o.show) ?
                    <MenuItem
                        key={o.value}
                        value={o.value}
                        data-testid-label={o.label}
                        disabled={o?.disabled ? true : false}
                    >
                        {multiple && (
                            <>
                                {/* According to WCAG we need label for input field
                                  * https://www.w3.org/WAI/tutorials/forms/labels */}
                                <label className={clsx(WCAGClasses.hiddenLabel)} htmlFor={optionId}>
                                    {o.label ?? o.primary}
                                </label>
                                <StyledCheckbox id={optionId} checked={!!(value && value.includes(o.value))}/>
                            </>
                        )}
                        {limitWidth ? <Tooltip title={o.label}>
                            <LimitWidthListItemText
                                primary={o.label ?? o.primary}
                                secondary={o.label ? null : o.secondary}
                                style={{width: itemTextWith}}
                            />
                        </Tooltip> : <ListItemText
                            primary={o.label ?? o.primary}
                            secondary={o.label ? null : o.secondary}
                        />}
                    </MenuItem> : null
                )
            })}
        </Select>

        {helperText
            ? <FormHelperText>
                {helperText}
            </FormHelperText>
            : null}
    </FormControl>;
});

OutlinedSelectField.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.node.isRequired,
    })),
};

export default OutlinedSelectField;