export var PortalID = {
    SCHOOL: 'school',
    PERFORMER: 'performer',
    ADMINISTRATOR: 'administrator'
};
export var FieldLimit = {
    TEXT: 255,
    MSG_SUBJECT: 800,
    SHORT_TEXT: 750,
    MEDIUM_TEXT: 1500,
    LONG_TEXT: 10000,
    NUMBER: 100000
};
export var STATUS = {
    CANCELED: 'canceled'
};
export var Language = {
    NB: 'nb',
    NN: 'nn',
    EN: 'en'
};
export var STYLES = {
    FONT_SIZE: {
        H1: '3rem',
        H2: '3rem',
        H3: '2.125rem',
        H4: '1.5rem',
        H5: '1.25rem',
        H6: '1.25rem',
        SUBTITLE_1: '1rem',
        SUBTITLE_2: '0.875rem'
    },
    LINE_HEIGHT: {
        DEFAULT: 1.5,
        H6: 1.6,
        SUBTITLE_1: 1.75,
        SUBTITLE_2: 1.57
    },
    FONT_WEIGHT: {
        500: 500,
        400: 400
    },
    LETTER_SPACING: {
        LETTER_SPACING_1: '0.0075em',
        LETTER_SPACING_2: '0.00735em',
        LETTER_SPACING_3: '0em',
        SUBTITLE_1: '0.00938em',
        SUBTITLE_2: '0.00714em'
    },
    DISPLAY: {
        INLINE_BLOCK: 'inline-block'
    },
    OVERFLOW: {
        HIDDEN: 'hidden'
    }
};
export var LanguageHTML = {
    en: 'en-GB',
    nb: 'nb-NO',
    nn: 'nn-NO'
};
export var AttributeHTML = {
    LANG: 'lang'
};
export var CK_EDITOR = {
    MODE: {
        SOURCE: 'source',
        WYSIWYG: 'wysiwyg'
    },
    CONFIG_VERSION: {
        DEFAULT: 'default',
        V2: 'v2'
    },
    EXTERNAL_PLUGINS: {
        ACCESSIBILITY_CHECKER: {
            NAME: 'a11ychecker',
            PATH: '/static/ckeditor/plugins/a11ychecker/'
        },
        FORMAT_BUTTONS: {
            NAME: 'format_buttons',
            PATH: '/static/ckeditor/plugins/format_buttons/'
        },
    },
    CONFIG: {
        KEY: {
            TOOLBAR: "toolbar",
            EXTRA_PLUGINS: "extraPlugins"
        },
        DEFAULT: {
            LANGUAGE: Language.NB,
            EXTRA_PLUGINS: 'autoembed,notificationaggregator,notification,embedbase,embed',
            TOOLBAR: [['Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', 'Table', 'Undo', 'Redo', 'Embed']],
            EMBED_PROVIDER: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
            BASE_FLOAT_Z_INDEX: 9001
        },
        V2: {
            EXTRA_PLUGINS: 'autoembed,notificationaggregator,notification,embedbase,embed,a11ychecker,format_buttons',
            TOOLBAR: [['A11ychecker', 'Source', 'h2', 'h3', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', 'Table', 'Undo', 'Redo', 'Embed']],
            CONTENT_CSS: '/css/ckeditor-custom.css'
        },
        MESSAGE: {
            WIDTH: '100%',
            HEIGHT: '100px',
            EXTRA_PLUGINS: 'editorplaceholder,colorbutton',
            TOOLBAR: [['Format', 'Link', 'TextColor', 'Bold', 'Italic', 'BulletedList', 'NumberedList']],
            ENTITIES: false,
            CONTENT_CSS: '/css/ckeditor-message.css'
        }
    }
};
export var CHARACTER = {
    COMMA: ',',
    HYPHEN: '-',
    UNDERSCORE: '_',
    DOT: '.'
};
export var KEYCODES = {
    ENTER: 13
};
export var STATUS_CODE = {
    OK: 200
};
export var LOCATION_NAME = {
    UNSPECIFIED: 'Uspesifisert'
};
export var TIME_ZONE = {
    EUROPE_OSLO: 'Europe/Oslo'
};
export var PROGRAM_TYPE;
(function (PROGRAM_TYPE) {
    PROGRAM_TYPE["application"] = "application";
    PROGRAM_TYPE["production"] = "production";
})(PROGRAM_TYPE || (PROGRAM_TYPE = {}));
export var OPTIONS_LANGUAGE = [
    { value: "nb", label: "Norsk bokmål" },
    { value: "nn", label: "Norsk nynorsk" }
];
export var DEFAULT_LANGUAGE = 'nb';
export var NUMBER_OF_ITEMS_TO_LOAD = 20;
export var IMAGE_STORAGE_SERVICES = {
    FOTOWARE: 'fotoware',
    IMAGESHOP: 'imageshop'
};
export var MESSAGE_IDENTITY = {
    USER_ADMINISTRATOR: 'user_administrator',
    USER_SCHOOL: 'user_school',
    USER_PERFORMER: 'user_performer'
};
export var EDIT_USER_DISABLED_BASE_FIELDS = ['name', 'email', 'phoneNumber', 'organizationNumber'];
export var HTTP_STATUS_CODE = {
    TRANSLATE_MESSAGE: 292
};
export var TEMPLATE_GRADE_LEVELS = {
    MELLOMTRINNET: '5th-7th',
    UNGDOMSTRINNET: '8th-10th'
};
